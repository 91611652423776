export function useEditorScrollToggle(delay: number = 1000) {
  const system = useSystemStore();
  const { editorIsNavigating, navigationFallbackTimer } = storeToRefs(system);

  const handleMouseOver = () => {
    if (navigationFallbackTimer.value) {
      clearTimeout(navigationFallbackTimer.value);
    }
    editorIsNavigating.value = true;
  };

  const handleMouseOut = () => {
    if (navigationFallbackTimer.value) {
      clearTimeout(navigationFallbackTimer.value);
    }
    navigationFallbackTimer.value = setTimeout(() => {
      editorIsNavigating.value = false;
    }, delay);
  };

  return {
    handleMouseOver,
    handleMouseOut,
  };
}
